import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { MenuItem } from "primereact/menuitem";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import { RoutesEnum, RoutesParametersEnum } from "@app/models/RoutesEnum";
import { CompanyACLUtil, UserACLUtil } from "@dashart/dashart-gpt-shared-library/dist";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import "./MainMenu.scss";

interface MainMenuProps {
  user: UserInterface | null;
  availableServices: {
    chatGpt: boolean;
    claude: boolean;
    mistral: boolean;
    gemini: boolean;
  };
  tryLogout: () => void;
}

const MainMenu: React.FC<MainMenuProps> = ({ user, availableServices, tryLogout }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isActiveRoute = (path: string): boolean =>
    matchPath({ path, end: true }, location.pathname) !== null;

  const addActiveClass = (routesToMatch: string[]): string =>
    routesToMatch.some(isActiveRoute) ? "p-menuitem-active" : "";

  const spacer: MenuItem = {
    disabled: true,
    template: () => <div className="p-menuitem-separator mr-2 ml-2">|</div>,
  };

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [
      {
        label: t("menu.dashboard"),
        url: "#" + RoutesEnum.dashboard,
        className: addActiveClass([RoutesEnum.dashboard]),
      },
      spacer,
    ];

    const chatServices = [
      { key: "chatGpt", route: RoutesEnum.chatWithChatGpt },
      { key: "claude", route: RoutesEnum.chatWithClaude },
      { key: "mistral", route: RoutesEnum.chatWithMistral },
      { key: "gemini", route: RoutesEnum.chatWithGemini },
    ];

    chatServices.forEach(({ key, route }) => {
      if (availableServices[key as keyof typeof availableServices]) {
        items.push({
          label: t(`menu.chatWith${key.charAt(0).toUpperCase() + key.slice(1)}`),
          url: "#" + route,
          className: addActiveClass([route]),
        });
      }
    });

    items.push(spacer);

    if (user && UserACLUtil.canUserListAllUsers(user)) {
      items.push({
        label: t("menu.users"),
        url: "#" + RoutesEnum.userList,
        className: addActiveClass([
          RoutesEnum.userList,
          RoutesEnum.userCreate,
          RoutesEnum.userDetail,
          RoutesEnum.userEdit,
          RoutesEnum.userDelete,
        ]),
      });
    }

    if (user && CompanyACLUtil.canUserListAllCompanies(user)) {
      items.push({
        label: t("menu.companies"),
        url: "#" + RoutesEnum.companyList,
        className: addActiveClass([
          RoutesEnum.companyList,
          RoutesEnum.companyCreate,
          RoutesEnum.companyDetail,
          RoutesEnum.companyEdit,
          RoutesEnum.companyDelete,
          RoutesEnum.companyListUsers,
        ]),
      });
    }

    if (user?.companyId && CompanyACLUtil.canUserListUsersOfHisCompany(user, { id: user.companyId })) {
      items.push({
        label: t("menu.companiesUsers"),
        url: "#" + RoutesEnum.companyListUsers.replace(RoutesParametersEnum.companyId, user.companyId.toString()),
        className: addActiveClass([
          RoutesEnum.companyList,
          RoutesEnum.companyCreate,
          RoutesEnum.companyDetail,
          RoutesEnum.companyEdit,
          RoutesEnum.companyDelete,
          RoutesEnum.companyListUsers,
        ]),
      });
    }

    return items;
  }, [user, availableServices, location]);

  return (
    <div className="MainMenu col p-col-nogutter">
      <Menubar
        model={menuItems}
        end={
          <>
            <ButtonWithLink
              label={`${user?.firstName} ${user?.familyName}`}
              className="p-button-secondary"
              icon="pi pi-user"
              style={{ marginLeft: 4 }}
              href={RoutesEnum.userEdit.replace(RoutesParametersEnum.userId, user?.id?.toString() || "")}
            />
            <Button label={t("menu.logout")} icon="pi pi-power-off" style={{ marginLeft: 4 }} onClick={tryLogout} />
          </>
        }
      />
    </div>
  );
};

export default MainMenu;