import * as React from "react";
import { memo, useMemo, useRef } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

interface SyntaxHighlighterCodeProps {
    inline?: boolean;
    className?: string;
    children: React.ReactNode;
    [key: string]: any;
}

// Type for the cache map
type LanguageCache = Map<string, string | null>;

export const SyntaxHighlighterCode = memo(({
    inline,
    className = "code",
    children,
    ...props
}: SyntaxHighlighterCodeProps) => {
    // Create a cache map of processed classNames
    const classNameCache = useRef<LanguageCache>(new Map());

    const language = useMemo(() => {
        if (!className) return null;
        
        // Check if result is already cached
        if (classNameCache.current.has(className)) {
            return classNameCache.current.get(className);
        }

        // Process new className
        const prefix = 'language-';
        let result = null;
        
        if (className.startsWith(prefix)) {
            result = className.slice(prefix.length);
        }
        
        // Cache the result
        classNameCache.current.set(className, result);
        return result;
    }, [className]);

    if (!inline && language) {
        return (
            <SyntaxHighlighter
                style={a11yDark as any}
                language={language}
                PreTag="div"
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        );
    }

    return (
        <code className={className} {...props}>
            {children}
        </code>
    );
});

SyntaxHighlighterCode.displayName = 'SyntaxHighlighterCode';

export default SyntaxHighlighterCode;
