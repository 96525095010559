import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import { Message } from "primereact/message";

import "./LoginScreen.scss";
import { RoutesEnum } from "@app/models/RoutesEnum";
import ProgressSpinnerOverlay from "@view/components/progressSpinnerOverlay/ProgressSpinnerOverlay";
import BaseInput, { BaseInputType } from "@view/components/inputs/baseInput/BaseInput";

export interface LoginScreenProps {
    loading: boolean;
    loginFailed: boolean;
    overlay: boolean;
    login: (userName: string, password: string) => void;
}

interface FormData {
    userName: string;
    password: string;
}

const LoginScreen: React.FC<LoginScreenProps> = ({ loading, loginFailed, overlay, login }) => {
    const { t } = useTranslation();
    const [redirectTo, setRedirectTo] = useState<string | null>(null);
    const [formData, setFormData] = useState<FormData>({ userName: "", password: "" });
    const [dialogStyle, setDialogStyle] = useState({ width: '50vw' });

    useEffect(() => {
        const handleResize = () => {
            setDialogStyle({ width: window.innerWidth < 1024 ? '100vw' : '50vw' });
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const updateFormData = useCallback((field: keyof FormData, value: string) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }, []);

    const onClearHandler = useCallback(() => setFormData({ userName: "", password: "" }), []);

    const onLoginHandler = useCallback(() => login(formData.userName, formData.password), [login, formData]);

    const onResetHandler = useCallback(() => setRedirectTo(RoutesEnum.forgottenPassword), []);

    const onOuterKeyHandler = useCallback((evt: React.KeyboardEvent) => {
        if (evt.code.toLowerCase() === "enter") {
            onLoginHandler();
        }
    }, [onLoginHandler]);

    const renderDialogContent = () => (
        <Dialog
            header={t('components.login_screen.header')}
            visible={true}
            style={dialogStyle}
            modal
            closable={false}
            onHide={() => { }}
        >
            <div className="LoginScreen formgrid grid">
                {redirectTo && <Navigate to={redirectTo} />}
                <ProgressSpinnerOverlay visible={loading} />
                <div className="col-12 p-fluid col-nogutter" onKeyPressCapture={onOuterKeyHandler}>
                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes="col-12"
                        label={t('components.login_screen.field_email')}
                        value={formData.userName}
                        handlerChange={(data: string) => updateFormData('userName', data)}
                    />
                    <BaseInput
                        type={BaseInputType.Password}
                        classes="col-12"
                        label={t('components.login_screen.field_password')}
                        value={formData.password}
                        handlerChange={(data: string) => updateFormData('password', data)}
                    />
                    {loginFailed && (
                        <div className="col-12 mb-2">
                            <Message severity="error" text={t('components.login_screen.message_login_failed')} />
                        </div>
                    )}
                </div>
                <Menubar
                    className="col-12 loginMenubar"
                    model={[]}
                    end={
                        <>
                            <Button
                                className="p-button-secondary ml-2"
                                label={t('globals.actions.cancel')}
                                onClick={onClearHandler}
                                icon="pi pi-times"
                            />
                            <Button
                                className="ml-2"
                                label={t('globals.actions.login')}
                                onClick={onLoginHandler}
                                icon="pi pi-check"
                            />
                        </>
                    }
                />
            </div>
        </Dialog>
    );

    return overlay ? renderDialogContent() : (
        <div className="LoginBox flex p-grid col align-items-center justify-content-center">
            <div className="col-8">
                {renderDialogContent()}
            </div>
        </div>
    );
};

export default LoginScreen;