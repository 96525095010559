import { BaseService } from "@app/services/BaseService";
import { ChatModelInterface } from "../interfaces/ChatModelInterface";
import { ChatServiceInterface } from "../interfaces/ChatServiceInterface";

/**
 * Service class for interacting with Claude APIs.
 */
export class GeminiService extends BaseService implements ChatServiceInterface {

    /** Base name for GPT models, used for history entries detection */
    readonly modelBaseNameStart: string = 'gemini';

    /** Singleton instance of GeminiService */
    private static _instance: GeminiService;

    constructor() {
        super();
    }

    protected getConfiguration(): { defaultModel: string; apiResourceName: string; } {
        return {
            defaultModel: 'gemini-1.5-flash',
            apiResourceName: 'gemini',
        }
    }

    /**
     * Gets the singleton instance of GeminiService.
     * @returns Singleton instance of GeminiService.
     */
    public static getInstance(): GeminiService {
        return this._instance ?? (this._instance = new GeminiService());
    }

    /**
     * Retrieves the available chat models.
     * @returns Promise resolving to an array of ChatGptModelInterface that match chat model criteria.
     */
    public async getAvailableChatModels(): Promise<ChatModelInterface[]> {
        const models = await this.getAvailableModels();
        return models.filter(model => model.id.includes(`gemini`));
    }
}