// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print{.MainMenu{display:none}}`, "",{"version":3,"sources":["webpack://./src/view/components/mainMenu/MainMenu.scss"],"names":[],"mappings":"AACI,aADJ,UAEQ,YAAA,CAAA","sourcesContent":[".MainMenu {\n    @media print {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainMenu": `MainMenu`
};
export default ___CSS_LOADER_EXPORT___;
