import {AxiosInstance, AxiosResponse} from "axios";
import {AxiosUtil} from "@app/utils/AxiosUtil";
import {AuthUtil} from "@app/utils/AuthUtil";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";

export class AuthService {

    private static axiosInstance: AxiosInstance;
    private static rawAxiosInstance: AxiosInstance;

    private static getAxiosInstance(): AxiosInstance {
        if (!this.axiosInstance) {
            this.axiosInstance = AxiosUtil.createPreconfiguredAxiosInstance();
        }

        return this.axiosInstance;
    }

    private static getRawAxiosInstance(): AxiosInstance {
        if (!this.rawAxiosInstance) {
            this.rawAxiosInstance = AxiosUtil.createNonConfiguredAxiosInstance();
        }

        return this.rawAxiosInstance;
    }

    /**
     * Log user in
     * @param userName
     * @param password
     */
    static async login(userName: string, password: string): Promise<UserInterface> {
        return this.getRawAxiosInstance().post(
            '/v1/auth/login',
            {
                "email": userName,
                "password": password
            }
        ).then(
            (response: AxiosResponse) => {
                if (response.status === 200) {

                    AuthUtil.setAccessToken(response.data?.accessToken ?? '');
                    AuthUtil.setDeviceId(response.data?.deviceId ?? 'web');
                    const tokenPayload = AuthUtil.getTokenPayload(
                        AuthUtil.setRefreshToken(response.data?.refreshToken ?? '')
                    )

                    return Promise.resolve(<UserInterface>tokenPayload.user)
                } else {
                    return Promise.reject(response)
                }
            },
            (error) => {
                console.log('login failed', error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Validate if session is still valid and get new refresh token
     */
    static async checkIfSessionValidAndGetNewRefreshToken(): Promise<UserInterface | null> {
        return this.getRawAxiosInstance().post(
            '/v1/auth/refresh-session',
            {
                refreshToken: AuthUtil.getRefreshToken()
            },
        ).then(
            (response: AxiosResponse) => {
                if (response.status === 200) {
                    const accessToken = response.data ?? '';
                    AuthUtil.setAccessToken(accessToken ?? '');
                    const tokenPayload = AuthUtil.getTokenPayload(accessToken)
                    return Promise.resolve(tokenPayload.user ?? null)
                } else {
                    return Promise.reject(null)
                }
            },
            (error) => {
                console.log('session not valid', error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Get new access token, but this will return a complete fresh jwt token
     */
    static async requestNewAccessToken(refreshToken: any): Promise<any | null> {
        return this.getRawAxiosInstance().post(
            '/v1/auth/refresh-access-token',
            {
                refreshToken: refreshToken,
            }
        ).then(
            (response: AxiosResponse) => {
                if (response.status === 200) {

                    AuthUtil.setAccessToken(response.data ?? '');

                    return Promise.resolve(response.data ?? null)
                } else {
                    return Promise.reject(null)
                }
            },
            (error) => {
                console.log('session not valid', error);
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    };

    /**
     * Log user out
     */
    static async logout(): Promise<boolean> {
        try {
            const response: AxiosResponse = await this.getAxiosInstance().post(
                '/v1/auth/logout',
                {},
                {
                    headers: {
                        'Authorization': 'Bearer ' + (AuthUtil.getAccessToken() ?? '')
                    }
                }
            );
        } catch (error) {
            console.log('logout failed', error);
            return Promise.reject(error?.response?.data ?? error);
        }

        // remove also if it fails
        AuthUtil.clearTokens();

        return true;
    };

    /*static async sendPwdResetMail(dto: RequestPasswordResetTokenDto): Promise<any> {
        return this.getRawAxiosInstance().post(
            'auth/sendPwdResetMail',
            dto
        ).then(
            (response: AxiosResponse) => {
                if (response.status == 201) {
                    return Promise.resolve(response.data)
                } else {
                    return Promise.reject(response.data)
                }
            },
            (error) => {
                console.log('pwd reset failed', error)
                return Promise.reject(error?.response?.data ?? error)
            }
        )
    }*/

    /*static async resetPassword(dto: ChangePasswordDto): Promise<any> {
        return this.getRawAxiosInstance().post(
            'auth/resetPassword',
            dto
        ).then(
            (response) => {
                if (response.status == 201) {
                    return Promise.resolve()
                } else {
                    return Promise.reject()
                }
            },
            (error) => {
                return Promise.reject()
            }
        )
    }

    static async verifyPwdResetToken(dto: VerifyPasswordResetTokenDto): Promise<any> {
        return this.getRawAxiosInstance().post(
            'auth/verifyPwdResetToken',
            dto
        ).then(
            (response) => {
                if (response.status == 201) {
                    return Promise.resolve()
                } else {
                    return Promise.reject()
                }
            },
            (error) => {
                return Promise.reject()
            }
        )
    }*/
}