import { ToastMessageInterface } from "@app/interfaces/ToastMessageInterface";
import { RoutesEnum, RoutesParametersEnum } from "@app/models/RoutesEnum";
import { CompanyInterface, CreateCompanyDto } from "@dashart/dashart-gpt-shared-library";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import ConfirmDialog from "@view/components/confirmDialog/ConfirmDialog";
import FormValidationErrorFieldset from "@view/components/formValidationErrorFieldset/FormValidationErrorFieldset";
import BaseInput, { BaseInputType } from "@view/components/inputs/baseInput/BaseInput";
import ProgressSpinnerOverlay from "@view/components/progressSpinnerOverlay/ProgressSpinnerOverlay";
import RoleValidatorComponent from "@view/components/roleValidatorComponent/RoleValidatorComponent";
import { transformAndValidateSync } from "class-transformer-validator";
import { ValidationError } from "class-validator";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyService } from "../../../app/services/CompanyService";
import "./CompanyDetailScreen.scss";

export enum CompanyDetailScreenMode {
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    VIEW = "VIEW",
    UNKNOWN = "UNKNOWN",
}

export interface CompanyDetailScreenProps {
    currentUser: UserInterface;
    mode: CompanyDetailScreenMode;
    showToast: (toast: ToastMessageInterface) => void
}

interface FormData extends CompanyInterface {
}

const CompanyDetailScreen: React.FunctionComponent<CompanyDetailScreenProps> = (props: CompanyDetailScreenProps) => {
    const { currentUser, mode } = props;
    const { t } = useTranslation();
    const { companyId } = useParams();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const [serverActionPending, setServerActionPending] = useState(false);
    const [formData, setFormData] = useState<FormData>(null);
    const [formErrors, setFormErrors] = useState<ValidationError[]>([]);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setIsEditMode(mode === CompanyDetailScreenMode.CREATE || mode === CompanyDetailScreenMode.UPDATE);
    }, [formData, currentUser, props.mode])

    /**
     * Load company from backend
     * @param companyId
     */
    const loadCompany = async (companyId: string) => {
        setServerActionPending(true);
        try {
            setFormData(await CompanyService.getById(companyId));
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.loadFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
            navigate(RoutesEnum.companyList);
        }
        setServerActionPending(false);
    };

    /**
     * Delete company from backend
     * @param companyId
     */
    const deleteCompany = async (companyId: string) => {
        setServerActionPending(true);
        try {
            await CompanyService.delete(companyId);
            props.showToast({
                severity: "success",
                summary: t('globals.deleteSuccessNotice'),
            });
            navigate(RoutesEnum.companyList);
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.deleteFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    };

    /**
     * Update company in backend
     * @param companyId
     */
    const updateCompany = async (companyId: string) => {
        setServerActionPending(true);
        try {
            const update = await CompanyService.update(companyId, formData);
            props.showToast({
                severity: "success",
                summary: t('globals.updateSuccessNotice'),
            });
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.updateFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    };

    /**
     * Create company in backend
     */
    const createCompany = async () => {
        setServerActionPending(true);
        try {
            const company = await CompanyService.create(formData);
            props.showToast({
                severity: "success",
                summary: t('globals.createSuccessNotice'),
            });
            navigate(RoutesEnum.companyDetail.replace(RoutesParametersEnum.companyId, `${company.id}`));
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.createFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
        }
        setServerActionPending(false);
    };

    useEffect(() => {
        if (companyId) {
            loadCompany(companyId);
        }
    }, [companyId]);

    const setAndValidateShopData = (formData: FormData) => {
        if (formData) {
            formData = { ...formData };
            validateInputs(formData);
        }

        setFormData(formData);
    }

    const validateInputs = (formData: FormData): ValidationError[] => {
        try {
            transformAndValidateSync(CreateCompanyDto, formData);
            setFormErrors([]);
            return [];
        } catch (err) {
            setFormErrors(err);
            return err;
        }
    }

    /**
     * Validates the logic and triggers form data save
     */
    const onSaveClickHandler = async () => {
        const errors = validateInputs(formData);

        if (errors?.length > 0) {
            props.showToast({
                severity: "error",
                summary: t('globals.validationFailedNotice')
            });
        } else {
            if (mode === CompanyDetailScreenMode.UPDATE) {
                await updateCompany(companyId);
            } else if (mode === CompanyDetailScreenMode.CREATE) {
                await createCompany();
            }
        }
    }

    /**
     * Delete company after confirmation modal
     */
    const onDeleteClickHandler = async () => {
        setShowDeleteConfirmation(true);
    }

    const onDeleteConfirmYesHandler = () => {
        setShowDeleteConfirmation(false);
        setServerActionPending(true);

        deleteCompany(companyId);
    }

    const onDeleteConfirmNoHandler = () => {
        setShowDeleteConfirmation(false);
    }

    const actionButtons = () => {
        if (mode === CompanyDetailScreenMode.CREATE) {
            return <>
                <ButtonWithLink
                    className="button-secondary"
                    label={t('globals.actions.cancel')}
                    icon="pi pi-times"
                    href={RoutesEnum.companyList}
                />
                <Button
                    className="ml-2"
                    label={t('globals.actions.create')}
                    icon="pi pi-check"
                    onClick={onSaveClickHandler}
                />
            </>
        } else if (mode === CompanyDetailScreenMode.UPDATE) {
            return <>
                <ButtonWithLink
                    className="button-secondary"
                    label={t('globals.actions.dismiss')}
                    icon="pi pi-times"
                    href={RoutesEnum.companyList}
                />
                <Button
                    className="ml-2"
                    label={t('globals.actions.save')}
                    icon="pi pi-check"
                    onClick={onSaveClickHandler}
                />
            </>
        } else if (mode === CompanyDetailScreenMode.DELETE) {
            return <>
                <ButtonWithLink
                    className="button-secondary"
                    label={t('globals.actions.backToOverview')}
                    icon="pi pi-chevron-left"
                    href={RoutesEnum.companyList}
                />
                <Button
                    className="ml-2 button-danger"
                    label={t('globals.actions.delete')}
                    icon="pi pi-trash"
                    onClick={onDeleteClickHandler}
                />
            </>
        }

        return <>
            <ButtonWithLink
                className="button-secondary"
                label={t('globals.actions.backToOverview')}
                icon="pi pi-chevron-left"
                href={RoutesEnum.companyList}
            />
        </>;
    };

    /**
     * Render delete confirmation dialog if needed
     */
    const deleteConfirmation = () => {
        if (!showDeleteConfirmation) {
            return null;
        }

        return <ConfirmDialog
            headline={t("company_management.deleteConfirmation.headline")}
            body={t(
                "company_management.deleteConfirmation.body",
                formData
            )}
            confirmLabel={t('globals.actions.yes')}
            cancelLabel={t('globals.actions.no')}
            onConfirm={onDeleteConfirmYesHandler}
            onCancel={onDeleteConfirmNoHandler}
            onHide={onDeleteConfirmNoHandler}
        />;
    }

    return <div className='CompanyDetailScreen'>
        {/*Validate access rights*/}
        <RoleValidatorComponent
            currentUser={currentUser}
            validationType={'companyScope'}
            companyOfInterest={formData}
            redirectRouteIfNotAllowed={RoutesEnum.dashboard}
        />

        {deleteConfirmation()}

        <Panel header={t("company_management.detail_screen.header")} className={"p-4"}>

            <Fieldset legend={t("company_management.detail_screen.contactHeader")} className={'mb-4'}>

                <ProgressSpinnerOverlay visible={serverActionPending} />

                <div className="p-fluid formgrid grid">
                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("company_management.detail_screen.label_name")}
                        value={formData?.name}
                        valueName={'name'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, name: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("company_management.detail_screen.label_email")}
                        value={formData?.email}
                        valueName={'email'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, email: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("company_management.detail_screen.label_phone")}
                        value={formData?.phone}
                        valueName={'phone'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, phone: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("company_management.detail_screen.label_website")}
                        value={formData?.website}
                        valueName={'website'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, website: data });
                            }
                        }
                    />

                </div>
            </Fieldset>

            <Fieldset legend={t("company_management.detail_screen.addressHeader")} className={'mb-4'}>
                <div className="p-fluid formgrid grid">

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-12'}
                        label={t("company_management.detail_screen.label_street")}
                        value={formData?.street}
                        valueName={'street'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, street: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-8'}
                        label={t("company_management.detail_screen.label_city")}
                        value={formData?.city}
                        valueName={'city'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, city: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-4'}
                        label={t("company_management.detail_screen.label_postalCode")}
                        value={formData?.postalCode}
                        valueName={'postalCode'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, postalCode: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("company_management.detail_screen.label_state")}
                        value={formData?.state}
                        valueName={'state'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, state: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-6'}
                        label={t("company_management.detail_screen.label_country")}
                        value={formData?.country}
                        valueName={'country'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, country: data });
                            }
                        }
                    />
                </div>

            </Fieldset>


            <Fieldset legend={t("company_management.detail_screen.apiTokensHeader")} className={'mb-4'}>
                <div className="p-fluid formgrid grid">

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-12'}
                        label={t("company_management.detail_screen.label_defaultOpenAiToken")}
                        value={formData?.defaultOpenAiToken}
                        valueName={'defaultOpenAiToken'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, defaultOpenAiToken: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-12'}
                        label={t("company_management.detail_screen.label_defaultClaudeToken")}
                        value={formData?.defaultClaudeToken}
                        valueName={'defaultClaudeToken'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, defaultClaudeToken: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-12'}
                        label={t("company_management.detail_screen.label_defaultMistralToken")}
                        value={formData?.defaultMistralToken}
                        valueName={'defaultMistralToken'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, defaultMistralToken: data });
                            }
                        }
                    />

                    <BaseInput
                        type={BaseInputType.TextInput}
                        classes={'col-12'}
                        label={t("company_management.detail_screen.label_defaultGeminiToken")}
                        value={formData?.defaultGeminiToken}
                        valueName={'defaultGeminiToken'}
                        formErrors={formErrors}
                        readOnly={!isEditMode}
                        handlerChange={
                            (data: any) => {
                                setAndValidateShopData({ ...formData, defaultGeminiToken: data });
                            }
                        }
                    />
                </div>

            </Fieldset>

            <div className={'messages-area'}>

                {formErrors?.length > 0 ? <FormValidationErrorFieldset
                    headline={t("company_management.detail_screen.label_validation_errors")}
                    translationKeyBase={"company_management.detail_screen.label_"}
                    validationErrors={formErrors}
                /> : null}

            </div>

            <div className="col-12 d-flex justify-end">
                {actionButtons()}
            </div>

        </Panel>

    </div>
}

export default CompanyDetailScreen;
