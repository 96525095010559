// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonWithIcon{display:flex;cursor:pointer;align-items:center;flex-direction:row;gap:.2rem;background:none;border:none;padding:0;appearance:none;-webkit-appearance:none;outline:none;cursor:pointer}.ButtonWithIcon:hover{opacity:.75}.ButtonWithIcon:focus-visible{outline:2px solid #007bff;outline-offset:2px}.ButtonWithIcon .label{margin-top:-4px}`, "",{"version":3,"sources":["webpack://./src/view/components/buttonWithIcon/ButtonWithIcon.module.scss"],"names":[],"mappings":"AAAA,gBACE,YAAA,CACA,cAAA,CACA,kBAAA,CACA,kBAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CACA,SAAA,CACA,eAAA,CACA,uBAAA,CACA,YAAA,CAEA,cAAA,CAEA,sBACE,WAAA,CAIF,8BACE,yBAAA,CACA,kBAAA,CAGF,uBACE,eAAA","sourcesContent":[".ButtonWithIcon {\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  flex-direction: row;\n  gap: 0.2rem;\n  background: none; // Add this\n  border: none; // Add this\n  padding: 0; // Add this\n  appearance: none; // Add this\n  -webkit-appearance: none; // Add this for Safari\n  outline: none; // Add this, but make sure to handle focus styles for accessibility\n\n  cursor: pointer;\n\n  &:hover {\n    opacity: .75;\n  }\n\n  // Add focus styles for accessibility\n  &:focus-visible {\n    outline: 2px solid #007bff; // or your theme color\n    outline-offset: 2px;\n  }\n\n  .label {\n    margin-top: -4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonWithIcon": `ButtonWithIcon`,
	"label": `label`
};
export default ___CSS_LOADER_EXPORT___;
