import { ButtonProps } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import * as React from "react";
import { useMemo } from "react";
import styles from "./ButtonWithIcon.module.scss";

export interface ButtonWithLinkProps extends ButtonProps {
    icon: React.ReactNode;
    label?: string;
    tooltip?: string;
    tooltipPosition?: "left" | "right" | "top" | "bottom" | undefined;
    additionalClassNames?: string;
    additionalLabelClassNames?: string;
    onClick?: () => void;
    'aria-label'?: string; // Add aria-label prop
}

const ButtonWithIcon: React.FunctionComponent<ButtonWithLinkProps> = (props: ButtonWithLinkProps) => {
    const uniqueDivId = useMemo(() => {
        return "unqId-" + Math.random().toString(36).substr(2, 9);
    }, []);

    const onClickHandler = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <button
            id={uniqueDivId}
            className={`${styles.ButtonWithIcon} ${props.additionalClassNames || ""}`}
            onClick={onClickHandler}
            type="button"
            role="button"
            title={props.tooltip}
            aria-label={props['aria-label'] || props.label} // Add aria-label
            disabled={props.disabled}
        >
            <Tooltip
                target={`#${uniqueDivId}`}
                content={props.tooltip}
                position={props.tooltipPosition}
            />

            <div className={styles.icon}>
                {props.icon}
            </div>

            {props.label && (
                <div className={`${styles.label} ${props.additionalLabelClassNames ?? ''}`}>
                    {props.label}
                </div>
            )}
        </button>
    );
}

export default ButtonWithIcon;