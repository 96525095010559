export class AuthUtil {
    private static readonly LS_KEYS = {
      DEVICE_ID: 'device_id',
      JWT_ACCESS_TOKEN: 'jwt_access_token',
      JWT_ACCESS_TOKEN_VALID_UNTIL: 'jwt_access_token_valid_until',
      JWT_REFRESH_TOKEN: 'jwt_refresh_token',
      JWT_REFRESH_TOKEN_VALID_UNTIL: 'jwt_refresh_token_valid_until',
    };
  
    private static getItem(key: string): string | null {
      return localStorage.getItem(key);
    }
  
    private static setItem(key: string, value: string): void {
      localStorage.setItem(key, value);
    }
  
    private static removeItem(key: string): void {
      localStorage.removeItem(key);
    }
  
    /**
     * Get the device id from local storage
     */
    static getDeviceId(): string | null {
      return this.getItem(this.LS_KEYS.DEVICE_ID);
    }
  
    /**
     * Set the device id in local storage
     * @param deviceId
     */
    static setDeviceId(deviceId: string): void {
      this.setItem(this.LS_KEYS.DEVICE_ID, deviceId);
    }
  
    /**
     * Get the payload from the token
     */
    static getTokenPayload(accessToken: string): any {
      try {
        return JSON.parse(atob(accessToken.split('.')[1]));
      } catch (e) {
        console.error('Invalid token', e);
        return null;
      }
    }
  
    /**
     * Clear all values from local storage
     */
    static clearTokens(): void {
      Object.values(this.LS_KEYS).forEach(key => this.removeItem(key));
    }
  
    /**
     * Get the refresh token from local storage
     */
    static getRefreshToken(): string | null {
      return this.getItem(this.LS_KEYS.JWT_REFRESH_TOKEN);
    }
  
    /**
     * Set the refresh token in local storage
     * @param token
     */
    static setRefreshToken(token: string): string {
      this.setItem(this.LS_KEYS.JWT_REFRESH_TOKEN, token);
      const decodedData = this.getTokenPayload(token);
      if (decodedData && decodedData.exp) {
        this.setItem(this.LS_KEYS.JWT_REFRESH_TOKEN_VALID_UNTIL, decodedData.exp.toString());
      }
      return token;
    }
  
    /**
     * Get refresh token valid until timestamp in seconds
     */
    static getRefreshTokenValidUntil(): number {
      return +(this.getItem(this.LS_KEYS.JWT_REFRESH_TOKEN_VALID_UNTIL) ?? 0);
    }
  
    /**
     * Check if the refresh token is expired
     */
    static getRefreshTokenExpired(): boolean {
      return this.getRefreshTokenValidUntil() * 1000 < Date.now();
    }
  
    /**
     * Get access token valid until timestamp in seconds
     */
    static getAccessTokenValidUntil(): number {
      return +(this.getItem(this.LS_KEYS.JWT_ACCESS_TOKEN_VALID_UNTIL) ?? 0);
    }
  
    /**
     * Check if the access token is expired
     */
    static getAccessTokenExpired(): boolean {
      return this.getAccessTokenValidUntil() * 1000 < Date.now();
    }
  
    /**
     * Get the access token from local storage
     */
    static getAccessToken(): string | null {
      return this.getItem(this.LS_KEYS.JWT_ACCESS_TOKEN);
    }
  
    /**
     * Set the access token in local storage
     * @param token
     */
    static setAccessToken(token: string): string {
      this.setItem(this.LS_KEYS.JWT_ACCESS_TOKEN, token);
      const decodedData = this.getTokenPayload(token);
      if (decodedData && decodedData.exp) {
        this.setItem(this.LS_KEYS.JWT_ACCESS_TOKEN_VALID_UNTIL, decodedData.exp.toString());
      }
      return token;
    }
  }
  