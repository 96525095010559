import * as React from 'react';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InputTextarea } from "primereact/inputtextarea";
import ButtonWithIcon from "@view/components/buttonWithIcon/ButtonWithIcon";
import { SendIcon } from "@view/components/icons/SendIcon";
import { CancelIcon } from "@view/components/icons/CancelIcon";

interface ChatInputProps {
    prompt: string;
    isRunning: boolean;
    textAreaLines: number;
    onPromptChange: (prompt: string) => void;
    onSubmit: () => void;
    onCancel: () => void;
}

const ICON_SIZE = 24;

export const ChatInput = memo(({
    prompt,
    isRunning,
    textAreaLines,
    onPromptChange,
    onSubmit,
    onCancel
}: ChatInputProps) => {
    const { t } = useTranslation();

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && event.metaKey) {
            onSubmit();
        }
    }, [onSubmit]);

    return (
        <div className="lower-section flex-grow-0 p-grid p-2 m-0 border-top-1">
            <div className="grid" onKeyDownCapture={handleKeyDown}>
                <div className="col-12 p-1 pb-0 mb-1 relative">
                    <InputTextarea
                        className='col-12 p-2 m-0'
                        placeholder={t('components.chat_input.your_prompt')}
                        rows={textAreaLines}
                        value={prompt}
                        onChange={(e) => onPromptChange(e.currentTarget.value)}
                    />
                    <div className='inputActionButtons absolute right-0 bottom-0 m-2'>
                        <ButtonWithIcon
                            icon={
                                !isRunning ? 
                                    <SendIcon width={ICON_SIZE} height={ICON_SIZE} /> :
                                    <CancelIcon width={ICON_SIZE} height={ICON_SIZE} />
                            }
                            onClick={!isRunning ? onSubmit : onCancel}
                            aria-label={!isRunning ? 'send' : 'cancel'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

ChatInput.displayName = 'ChatInput';

