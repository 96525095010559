import * as React from "react";
import "./FormValidationErrorFieldset.scss"
import {ValidationError} from "class-validator";
import {Fieldset} from "primereact/fieldset";
import {I18nUtil} from "@app/utils/I18nUtil";
import {useTranslation} from "react-i18next";

export interface FormValidationErrorFieldsetProps {
    headline: string;
    validationErrors: ValidationError[];
    translationKeyBase: string | string[];
}

const FormValidationErrorFieldset: React.FunctionComponent<FormValidationErrorFieldsetProps> = (props: FormValidationErrorFieldsetProps) => {
    const {t} = useTranslation();
    const renderError = (validationError: ValidationError) => {
        if (!validationError) {
            return null;
        }

        let label = t(`${props.translationKeyBase}${validationError?.property}`);

        // if we have multiple bases, check each base, until we have a translation
        if (Array.isArray(props.translationKeyBase)) {
            props.translationKeyBase.map(keyBase => {
                const key = `${keyBase}${validationError?.property}`;
                const translation = t(key);

                // if no translation, key and translation will be the same
                if (translation != key) {
                    label = translation;
                }
            });
        }

        const errorMessages = [];
        const keys = (validationError?.constraints ? Object.keys(validationError?.constraints) : []) ?? [];

        keys.map(
            key => {
                let value = validationError.constraints[key];
                const translation = I18nUtil.translateValidatorError(validationError.constraints[key]);
                return errorMessages.push(translation && translation != '' ? translation : value);
            }
        )

        return <li key={`e-${validationError.property}`}>
            <b>{label && label != '' ? label : validationError.property}</b>
            <ul>
                {errorMessages?.map((message, idx) => <li
                    key={`e-detail-${validationError.property}-${idx}`}>{message}</li>)}
            </ul>
        </li>
    }
    return <Fieldset className={'FormValidationErrorFieldset'} legend={props.headline}>
        {props.validationErrors?.map(renderError)}
    </Fieldset>
}

export default FormValidationErrorFieldset;