import * as React from "react";

import { ChatMessageInterface } from '@app/interfaces/ChatMessageInterface';
import CodeCopyBtn from '@view/components/codeCopyBtn/CodeCopyBtn';
import { memo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './ChatMessage.module.scss';
import ChatMessageOptionsMenu from './chatMessageOptionsMenu/ChatMessageOptionsMenu';
import SyntaxHighlighterCode from "./syntaxHighlighterCode/SyntaxHighlighterCode";

interface ChatMessageProps {
    message: ChatMessageInterface;
    index: number;
    onForkRequestHandler?: (messageIndex: number) => void;
}

const roleIcons = {
    bot: '🤖',
    user: '👤',
    default: '🖥️'
} as const;

const ButtonSection = memo(({ children }: { children: React.ReactNode }) => (
    <pre>
        <div className="print-hidden">
            <CodeCopyBtn>{children}</CodeCopyBtn>
        </div>
        {children}
    </pre>
));

const ChatMessage = memo(({ message, index, onForkRequestHandler }: ChatMessageProps) => {
    /**
     * Handle the fork request
     */
    const handleForkContent = useCallback(() => {
        if (onForkRequestHandler) {
            onForkRequestHandler(index);
        }
    }, [onForkRequestHandler, index]);

    return (
        <div
            className={`${styles.ChatMessage} markdown-body col-12 flex flex-row pr-5 ${index % 2 ? 'bg-white-alpha-90' : 'bg-white-alpha-50'
                }`}
        >
            <div className="absolute right-0 top-0 p-2 pr-3 print-hidden">
                <ChatMessageOptionsMenu
                    messageContents={message.content}
                    messageIndex={index}
                    onForkRequestHandler={handleForkContent}
                />
            </div>

            <div className={styles.iconColumn}>
                {roleIcons[message.role as keyof typeof roleIcons] || roleIcons.default}
            </div>

            <div className={styles.messageColumn}>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        pre: ButtonSection,
                        code: SyntaxHighlighterCode
                    }}
                >
                    {message.content}
                </ReactMarkdown>
            </div>
        </div>
    );
});

export default ChatMessage;