import { BaseService } from "@app/services/BaseService";
import { ChatModelInterface } from "../interfaces/ChatModelInterface";
import { ChatServiceInterface } from "../interfaces/ChatServiceInterface";

/**
 * Service class for interacting with Claude APIs.
 */
export class ClaudeService extends BaseService implements ChatServiceInterface {

    /** Base name for GPT models, used for history entries detection */
    readonly modelBaseNameStart: string = 'claude';

    /** Singleton instance of ClaudeService */
    private static _instance: ClaudeService;

    constructor() {
        super();
    }

    protected getConfiguration(): { defaultModel: string; apiResourceName: string; } {
        return {
            defaultModel: 'claude-3-5-sonnet-20240620',
            apiResourceName: 'claude',
        }
    }

    /**
     * Gets the singleton instance of ClaudeService.
     * @returns Singleton instance of ClaudeService.
     */
    public static getInstance(): ClaudeService {
        return this._instance ?? (this._instance = new ClaudeService());
    }

    /**
     * Retrieves the available chat models.
     * @returns Promise resolving to an array of ChatGptModelInterface that match chat model criteria.
     */
    public async getAvailableChatModels(): Promise<ChatModelInterface[]> {
        const models = await this.getAvailableModels();
        return models.filter(model => model.id.includes(`claude`));
    }
}