import { ChatModelInterface } from "@app/interfaces/ChatGptModelInterface";
import { BaseService } from "@app/services/BaseService";
import { ChatServiceInterface } from "../interfaces/ChatServiceInterface";

/**
 * Service class for interacting with Mistral APIs.
 */
export class MistralService extends BaseService implements ChatServiceInterface {

    /** Base name for GPT models, used for history entries detection */
    readonly modelBaseNameStart: string = 'mistral';

    /** Singleton instance of MistralService */
    private static _instance: MistralService;

    constructor() {
        super();
    }

    protected getConfiguration(): { defaultModel: string; apiResourceName: string; } {
        return {
            defaultModel: 'mistral-medium',
            apiResourceName: 'mistral',
        }
    }

    /**
     * Gets the singleton instance of MistralService.
     * @returns Singleton instance of MistralService.
     */
    public static getInstance(): MistralService {
        return this._instance ?? (this._instance = new MistralService());
    }

    /**
     * Retrieves the available chat models.
     * @returns Promise resolving to an array of ChatGptModelInterface that match chat model criteria.
     */
    public async getAvailableChatModels(): Promise<ChatModelInterface[]> {
        const models = await this.getAvailableModels();
        return models.filter(model => model.id.includes(`mistral`));
    }
}