import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonWithIcon from "@view/components/buttonWithIcon/ButtonWithIcon";
import { FreshIcon } from "@view/components/icons/FreshIcon";
import { ContinueIcon } from "@view/components/icons/ContinueIcon";

interface ChatDialogMenuProps {
    messagesExist: boolean;
    isRunning: boolean;
    continueOutputAvailable: boolean;
    onNewConversation: () => void;
    onContinueOutput: () => void;
}

const ICON_SIZE = 24;

export const ChatDialogMenu = memo(({
    messagesExist,
    isRunning,
    continueOutputAvailable,
    onNewConversation,
    onContinueOutput
}: ChatDialogMenuProps) => {
    const { t } = useTranslation();

    return (
        <div className='absolute right-0 bottom-0 m-2 mr-3 flex flex-column'
        data-testid="chat-dialog-menu"
        >
            {(messagesExist || isRunning) && (
                <ButtonWithIcon
                    icon={<FreshIcon width={ICON_SIZE} height={ICON_SIZE} />}
                    additionalClassNames='mb-2'
                    tooltip={t('components.chat_dialog_menu.start_new_chat')}
                    tooltipPosition='left'
                    onClick={onNewConversation}
                />
            )}
            {continueOutputAvailable && (
                <ButtonWithIcon
                    icon={<ContinueIcon width={ICON_SIZE} height={ICON_SIZE} />}
                    tooltip={t('components.chat_dialog_menu.continue_chat')}
                    tooltipPosition='left'
                    onClick={onContinueOutput}
                />
            )}
        </div>
    );
});

ChatDialogMenu.displayName = 'ChatDialogMenu';
